/* Packages */
import React, { useEffect, useState } from 'react'
import Fade from 'react-reveal/Fade'
/* Imported Components */
import Divider from '../divider/divider'
/* Styles */
import styles from './heading.module.scss'
/* Component */
const Heading = ({ device, section: { title, divider, text: { text } } }) => {
  const [sectionClasses, setSectionClasses] = useState(styles.section)

  useEffect(() => {
    setSectionClasses(device === 'computer' ? styles.section : styles.section + ' ' + styles.mobile)
  }, [device])

  return (
    <>
      <section className={sectionClasses}>
        <Fade bottom>
          <h1 className={styles.title}>
            {title}
          </h1>
        </Fade>
        <Fade bottom>
          <p className={styles.text}>
            {text}
          </p>
        </Fade>
      </section>
      {divider && <Divider device={device} />}
    </>
  )
}
/* Exports */
export default Heading
