/* Packages */
import React, { useEffect, useState } from 'react'
/* Imported Components */
/* Styles */
import styles from './divider.module.scss'
/* Component */
const Divider = ({ device }) => {
  const [hrClasses, setHrClasses] = useState(styles.divider)

  useEffect(() => {
    setTimeout(() => {
      setHrClasses(device === 'computer' ? styles.divider + ' ' + styles.show : styles.divider + ' ' + styles.show + ' ' + styles.mobile)
    }, 500)
  }, [device])

  return (
    <hr className={hrClasses} />
  )
}
/* Exports */
export default Divider
