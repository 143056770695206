/* Packages */
import React, { useContext, useEffect, useState } from 'react'
/* Imported Components */
import { Context } from '../context'
import { Layout } from '../components'
import Sections from '../components/future/sections/sections'
/* Styles */
import styles from './future.module.scss'
/* Component */
const Future = () => {
  const { windowSize: { device } } = useContext(Context)
  const [sectionClasses, setSectionClasses] = useState(styles.section)

  useEffect(() => {
    setSectionClasses(device === 'computer' ? styles.future : device === 'tablet' ? styles.future + ' ' + styles.tablet : styles.future + ' ' + styles.phone)
  }, [device])

  return (
    <Layout title='Future'>
      <section className={sectionClasses}>
        <Sections />
        <div className={styles.cover} />
      </section>
      <div className={styles.cover} />
    </Layout>
  )
}
/* Exports */
export default Future
