/* Packages */
import React, { useContext } from 'react'
/* Imported Components */
import { Context } from '../../../context'
import Heading from '../heading/heading'
import TextLeft from '../textLeft/textLeft'
import TextRight from '../textRight/textRight'
import Video from '../video/video'
/* Styles */
// import styles from './sections.module.scss'
/* Component */
const Sections = () => {
  const { futureSections, windowSize: { device } } = useContext(Context)

  return (
    futureSections.sort((a, b) => a.order < b.order ? -1 : 1).map(section => {
      const type = section.sectionDisplay

      return type === 'Title and Body' ? <Heading key={section.order} section={section} device={device} />
        : type === 'Title with Text Left and Image Right' ? <TextLeft key={section.order} section={section} device={device} />
          : type === 'Image Left and Title with Text Right' ? <TextRight key={section.order} section={section} device={device} />
            : <Video key={section.order} section={section} device={device} />
    })
  )
}
/* Exports */
export default Sections
