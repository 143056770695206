/* Packages */
import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
/* Imported Components */
import Divider from '../divider/divider'
/* Styles */
import styles from './textLeft.module.scss'
/* Component */
const TextLeft = ({ device, section: { title, divider, text, redText, image } }) => {
  const [sectionClasses, setSectionClasses] = useState(styles.section)

  useEffect(() => {
    setSectionClasses(device === 'computer' ? styles.section : styles.section + ' ' + styles.mobile)
  }, [device])

  return (
    <section className={sectionClasses}>
      <div className={styles.textDiv}>
        {title && (
          <Fade left>
            <h1 className={styles.title}>
              {title}
            </h1>
          </Fade>
        )}
        {text && text.text && (
          <Fade left>
            <p className={styles.text}>
              {text.text}
            </p>
          </Fade>
        )}
        {redText && redText.redText && (
          <Fade left>
            <p className={styles.redText}>
              {redText.redText}
            </p>
          </Fade>
        )}
      </div>
      {image && (
        <Fade right>
          <Img className={styles.image} fluid={image.fluid} alt='section' />
        </Fade>
      )}
      {divider && <Divider device={device} />}
    </section>
  )
}
/* Exports */
export default TextLeft
