/* Packages */
import React from 'react'
import ReactPlayer from 'react-player'
import Fade from 'react-reveal/Fade'
/* Imported Components */
import Divider from '../divider/divider'
/* Styles */
import styles from './video.module.scss'
/* Component */
const Video = ({ device, section: { videoUrl, divider } }) => {
  return (
    <section className={styles.section}>
      {videoUrl && (
        <Fade bottom>
          <div className={styles.videoWrapper}>
            <ReactPlayer id='videoContainer' width='100%' height='100%' className={styles.video} url={videoUrl} />
          </div>
        </Fade>
      )}
      {divider && <Divider device={device} />}
    </section>
  )
}
/* Exports */
export default Video
